<template>
  <div class="c-app flex-row align-items-center full-img-background">
    <ConfirmModal ref="AlertModal" :title="$t('cloud.title.alert')" :content="$t('cloud.title.login_fail')" color="danger" :ok_text="$t('button.ok')" :cancel_text="null" />
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="4">
          <CCardGroup>
            <CCard class="p-4 login-ui">
              <CCardBody>
                <CForm>
                  <!-- <div class="text-center px-4"><CImg src="/edgedx-brand2.png" class="w-100" /></div>
                  <p class="text-right text-warning">DIGITAL TRANSFORMATION FROM THE EDGE</p> -->
                  <CInput
                    ref="EmailInput"
                    :placeholder="$t('cloud.title.email')"
                    autocomplete="username email"
                    v-model="username"
                    @keyup="onKeyUp"
                  >
                    <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                  </CInput>
                  <CInput
                    ref="PasswordInput"
                    :placeholder="$t('cloud.title.password')"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                    @keyup="onKeyUp"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="reqLogin">{{ $t('button.login') }}</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
  name: 'Login',
  components: {
    ConfirmModal
  },
  data: function () {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    reqLogin: function() {
      if (!this.username) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.no_email'),
          offset: 30
        });
        this.$refs.EmailInput.focus();
        return;
      }
      if (!this.password) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.no_password'),
          offset: 30
        });
        this.$refs.PasswordInput.focus();
        return;
      }
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.username)) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.wrong_email'),
          offset: 30
        });
        this.$refs.EmailInput.focus();
        return false;
      }
      this.$api.Login({
        username: this.username,
        password: this.password
      }, () => {
        let redirect = '';
        if (this.$route.query.redirect) {
          redirect = `?redirect=${this.$route.query.redirect}`;
        }
        this.$router.push(`/pages/init-page${redirect}`);
      }, (error) => {
        console.error(error);
        this.$refs.AlertModal.open();
      })
    },
    onKeyUp: function(e) {
      if (e.key !== 'Enter') return;
      this.reqLogin();
    }
    // onDemo: function() {
    //   this.$api.Login({
    //     username: 'super-smart@demo-domain.com',
    //     password: 'edgedx.ai'
    //   }, (response) => {
    //     this.$router.push('/install');
    //   }, (error) => {
    //     console.error(error);
    //   })
    // }
  },
  mounted: function () {
    if (this.$store.state.capability) {
      this.$router.push('/');
    }
  }
}
</script>
